.modalTitle {
  font-size: 24px;
  line-height: 32px;
  color: #000;
  margin: auto;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
}

.box {
  border: 1px solid #0f62fe;
  width: 100%;
  padding: 1.5rem;
  margin-top: 1rem;
}

.box h3 {
  color: #0f62fe;
}

.box p {
  margin-top: 0.5rem;
}

.box img {
  border-radius: 8px;
  box-shadow: 0px 3px 4.5px rgba(0, 0, 0, 0.075), 0px -3px 4.5px rgba(0, 0, 0, 0.0375),
    3px 0px 4.5px rgba(0, 0, 0, 0.0375), -3px 0px 4.5px rgba(0, 0, 0, 0.0375);

  margin-top: 1rem;
  width: 100%;
}
