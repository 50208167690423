@import 'src/styles/variables/colors';

.wrapper {
  background-color: $light-blue;
}

.tabs {
  display: flex;
  column-gap: 36px;
  border-bottom: 1px solid $border-gray-color;
  padding-top: 12px;
}

.tab {
  font-weight: 600;
  position: relative;
  display: table-cell;
  transition: all ease 0.3s;
  padding: 0 0 12px;
  transform: translate3d(0, 0, 0);
  white-space: nowrap;
  cursor: pointer;

  &::after {
    transition: all 0.3s cubic-bezier(1, 0, 0, 1);
    will-change: transform, box-shadow, opacity;
    position: absolute;
    content: '';
    height: 2px;
    bottom: 0;
    left: 0;
    right: 0;
    background: $primary-color;
    opacity: 0;
    transform: scale(0, 1);
  }

  &.selected {
    color: $primary-color;

    &::after {
      opacity: 1;
      transform: scale(1, 1);
    }
  }

  &:hover {
    color: $primary-color;
  }
}

.leftEnter {
  opacity: 0;
  transform: translateX(-1000px);
}

.leftEnterActive {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

.leftExit {
  opacity: 1;
  position: absolute;
  transform: translateX(0);
}

.leftExitActive {
  opacity: 0;
  transform: translateX(-1000px);
  transition: opacity 500ms, transform 500ms;
}

.rightEnter {
  opacity: 0;
  transform: translateX(1000px);
}

.rightEnterActive {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

.rightExit {
  opacity: 1;
  position: absolute;
  transform: translateX(0);
}

.rightExitActive {
  opacity: 0;
  transform: translateX(1000px);
  transition: opacity 500ms, transform 500ms;
}
