@import 'src/styles/variables/colors';

.label {
  transition: all 0.3s;
  cursor: pointer;
  text-indent: -9999px;
  background: none;
  display: block;
  border-radius: 50px;
  position: relative;
}

.labelPrimary {
  width: 34px;
  height: 18px;

  &:active > div {
    width: 14px;
  }
}

.labelSecondary {
  width: 55px;
  height: 20px;

  &:active > div {
    width: 22px;
  }
}

.root {
  display: inline-flex;
  align-items: center;
  user-select: none;
  position: relative;
}

.input {
  height: 0;
  width: 0;
  opacity: 0;
}

.input:not(:checked) + label {
  > div {
    top: 50%;
    transform: translateY(-50%);
  }
}

.inputPrimary:not(:checked) + label {
  background-color: $disabled-color;

  > div {
    padding: 5px;
    left: 4px;
  }
}

.inputSecondary:not(:checked) + label {
  background: rgba(87 119 218 / 17%);

  > div {
    padding: 0;
    left: 0;
  }
}

.input:checked + label {
  > div {
    top: 50%;
    transform: translate(-100%, -50%);
  }
}

.inputPrimary:checked + label {
  background-color: #5777da;

  > div {
    padding: 5px;
    left: calc(100% - 4px);
  }
}

.inputSecondary:checked + label {
  background: rgba(87 119 218 / 17%);

  > div {
    padding: 0;
    left: calc(100% - 0px);
  }
}

.thumb {
  color: #fff;
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.thumbPrimary {
  width: 12px;
  height: 12px;
  background-color: #fff;
}

.thumbSecondary {
  width: 24px;
  height: 24px;
  background-color: $primary-color;
}
