@import 'src/styles/variables/colors';

.root {
  display: flex;
  justify-content: center;
}

.pages {
  display: flex;
  column-gap: 8px;
  margin: 0 8px;
}

.paginationItem {
  margin: 0 2px;
  padding: 5px;
  cursor: pointer;
  user-select: none;
}

.paginationButton {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.3s;
  border-radius: 2px;
  outline: 1px solid transparent;

  &:hover {
    outline: 1px solid lighten($primary-color, 30%);
  }

  &:active {
    background-color: lighten($primary-color, 30%);
  }
}

.disabled {
  cursor: default;
  pointer-events: none;
  border: none;

  &:hover {
    outline: 1px solid transparent;
  }

  &:active {
    background-color: transparent;
  }
}

.disabledArrow {
  opacity: 0;
  pointer-events: none;

  path {
    fill: #d3d3d3;
  }
}

.paginationNumber {
  width: 40px;
  height: 40px;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  color: $text-primary;
  outline: 1px solid transparent;

  &:hover {
    outline: 1px solid lighten($primary-color, 30%);
  }

  &:active {
    background-color: lighten($primary-color, 30%);
  }
}

.hidden {
  display: none;
}

.currentPage {
  color: #fff;
  background-color: $primary-color;
  border-radius: 2px;
  margin: 0 2px;
  padding: 5px;
  cursor: default;
  user-select: none;
}

.arrowLeft {
  transform: rotate(180deg);
}
