.callout {
  background: #f8c6f9;
  border-radius: 8px;
  color: #3c5076;
  font-size: 0.87rem;
  font-weight: 500;
  margin: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.callout h4 {
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  gap: 5px;
}

.callout h4 img {
  display: block;
  height: 100%;
  margin: 0;
  width: auto;
}

.callout img {
  display: block;
  height: auto;
  width: 100%;
  margin: 8px 0;
  border-radius: 5px;
}
