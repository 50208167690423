@import 'src/styles/variables/colors';

.root {
  display: flex;
  flex-direction: column;
}

.labelWrapper {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.single {
  display: block;
}

.label {
  color: $text-secondary;
  align-self: end;
  transition: all 0.3s;
}

.labelSecondary {
  font-weight: bold;
  color: #636363;
}

.labelError {
  color: $danger-color;
}

.wrapper {
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 14px 14px 14px 16px;
  width: 100%;
  border: 1px solid $primary-color;
  outline: 1px solid transparent;

  &:active,
  &:focus {
    outline: 1px solid $primary-color;
  }

  &:disabled {
    border: 1px solid $disabled-secondary-color;
    outline: none;
  }
}

.wrapperDisabled {
  background-color: #fafafa;
  border: 1px solid $disabled-secondary-color;
  outline: none;
}

.input {
  font-family: 'Red Hat Display', sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.03em;
  width: 100%;
  transition: all 0.3s;
  border: none;

  &:active,
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $text-placeholder;
  }

  &:disabled {
    background-color: #fafafa;
    cursor: not-allowed;
  }
}

.inputSecondary {
  color: #636363;
}

.inputError {
  border-color: $danger-color !important;

  &:active,
  &:focus {
    outline-color: $danger-color !important;
  }
}

.sublabel {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  color: $primary-color;
}

.error {
  margin-top: 8px;
  color: $danger-color;
  font-size: 14px;
  line-height: 19px;
  transition: all 0.3s;
}

.counterError {
  margin-top: 0;
  font-size: 19px;
}

.textarea {
  resize: none;
}

.countersWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.messagePreviewWrapper {
  position: relative;
  width: 358px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.messagePreview {
  word-break: break-all;
  white-space: pre-wrap;
  border-radius: 20px;
  padding: 8px 15px;
  display: inline-block;
  background: #e5e5ea;
  max-width: 251px;
  font-size: 13px;
  line-height: 17px;
}

.confirmationLinkAlert {
  font-size: 12px;
  line-height: 17px;
  color: #636363;
  text-align: right;
  margin-right: 3px;
}
