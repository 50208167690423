@import 'src/styles/variables/colors';

.blockTitle {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #cacaca;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: $primary-color;
}

.clickableText {
  cursor: pointer;
  text-decoration: underline;
  transition: 0.3s all;

  &:hover {
    color: lighten($primary-color, 15%);
  }
}

.tooltipLink {
  sup {
    cursor: pointer;

    &:hover {
      path {
        fill: lighten(#6c6c6c, 10%);
      }
    }
  }
}

.tooltipText {
  display: inline-block;
}
