@import 'src/styles/variables/media';

.root {
  position: relative;
  background: #fff;
  box-shadow: 0 2px 10px rgba(163 163 163 / 25%);
  border-radius: 8px;
  padding: 30px 35px 35px;
  width: 687px;
  min-height: 400px;

  @media ($phone) {
    min-height: 0;
    border-radius: unset;
    width: 100%;
    height: 100%;
  }
}

.loader {
  border-radius: 8px;

  @media ($phone) {
    border-radius: unset;
  }
}

.header {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.close {
  position: absolute;
  right: 25px;
  top: 23px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover {
    path {
      stroke: lighten(#3a3a3a, 20%);
    }
  }
}

.textField {
  margin-top: 30px;
}

.form {
  margin-top: 30px;
  height: 660px;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 67px;
}

.button {
  max-width: 250px;
}
