@import 'src/styles/variables/colors';

.root {
  margin-top: 24px;
}

.title {
  font-weight: 500;
}

.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  margin-top: 5px;
}

.listWrapper {
  margin-top: 20px;
}

.itemLabel {
  max-width: fit-content;
}
