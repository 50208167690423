@import 'src/styles/variables/colors';

.root {
  position: relative;
  display: inline-block;
}

.checkmark {
  opacity: 0;
  transition: all 0.3s;
}

.checkbox {
  opacity: 0;
  height: 0;
  width: 0;

  &:checked + label {
    background: $primary-color;
    border: 1px solid $primary-color;

    .checkmark {
      opacity: 1;
    }
  }
}

.label {
  width: 22px;
  height: 22px;
  border: 1px solid #606060;
  box-sizing: border-box;
  border-radius: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.labelDisabled {
  cursor: not-allowed;
  background-color: #dedede;
  opacity: 0.3;
}
