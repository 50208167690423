.cardContent {
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
}

.checkIcon {
  width: 50px;
  margin-right: 0.5rem;
  display: block;
}
