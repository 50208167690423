.modalBox {
  width: 80vw;
  height: 80vh;
  max-width: 900px;
  max-height: 500px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  padding: 16px;
  box-shadow: 24px;
  overflow: hidden; /* Ensure content does not overflow */
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: white;
  color: black;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure the button is on top */
}

.closeButton:hover {
  background-color: lightgrey;
}

.closeIcon {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

.widgetContainer {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 48px; /* Adjust padding to prevent overlapping */
}
