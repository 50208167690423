.root {
  position: relative;
  display: grid;
}

.input {
  opacity: 0;
}

.radio {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 17px;
  height: 17px;
  cursor: pointer;
  transition: all 0.3s;
}

.radioBorder {
  opacity: 1;
}

.radioDot {
  opacity: 0;
}

.input:checked + label {
  .radioDot {
    opacity: 1;
  }

  .radioBorder {
    opacity: 0;
  }
}
