@import 'src/styles/variables/colors';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.errorIcon {
  display: block;
}

.title {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.72px;
  margin-top: 22px;
}

.subtitle {
  letter-spacing: 0.54px;
  font-weight: 500;
  margin-top: 26px;
}

.description {
  font-size: 16px;
  letter-spacing: 0.48px;
  color: $text-light-gray;
  margin-top: 6px;
}

.calendarList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 16px;
  max-height: 127px;
  margin-top: 23px;
  overflow-y: auto;
  padding-bottom: 10px;

  &::-webkit-scrollbar {
    width: 0.25em;
    height: 0.25em;
  }

  &::-webkit-scrollbar-thumb {
    background: #96989b;
    border-radius: 10px;
    opacity: 0.7;
    overflow: auto;
  }
}

.calendarItem {
  width: 100%;
  list-style: none;
}

.line {
  width: 574px;
  border: 1px solid $border-light-gray-color;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 12px;
}

.button {
  width: 171px;
  height: 58px;
}

.fullWidthButton {
  width: 100%;
  height: 100%;
  font-size: 20px;
  flex-basis: 50%;
  border: 1px solid $primary-color;

  &:disabled {
    border: 1px solid $disabled-button-color;
  }
}
