$primary-color: #5777da;
$light-blue: #f9faff;
$text-primary: #272727;
$text-secondary: #676767;
$text-placeholder: #959595;
$text-light-gray: #5c5c5c;
$sub-primary-color: rgb(142 148 242 / 50%);
$light-purple-color: #8e94f2;
$danger-color: #cf1717;
$disabled-color: #dbdbdb;
$disabled-secondary-color: #767676ff;
$disabled-secondary-background-color: rgb(239 239 239 / 30%);
$disabled-button-color: #a6a6a6;
$success-color: #40ac0d;
$accepted-color: #438d20;
$waiting-color: #f4cf48;
$rejected-color: #d72626;
$border-color: #d9d9d9;
$border-gray-color: #e2e2e2;
$border-light-gray-color: #d6d6d6;
