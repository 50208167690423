@import 'src/styles/variables/colors';

.root {
  display: flex;
  justify-content: center;
}

.wrapper {
  display: flex;
  justify-content: center;
  justify-self: center;
  align-items: center;
}

.lineWrapper {
  flex: 1 1 auto;
}

.line {
  display: block;
  border-bottom: 1px solid $primary-color;
  justify-content: center;
  width: 80px;
}

.circle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  position: relative;
  border: 1px solid $primary-color;
  transition: all 0.3s;
}

.filled {
  background: $primary-color;
}

.number {
  transition: all 0.3s;
  color: $primary-color;
}

.active {
  color: #fff;
}
