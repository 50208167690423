@import 'src/styles/variables/colors';

.title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
}

.row {
  display: flex;
  column-gap: 5px;
  margin-bottom: 15px;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  min-width: 29px;
}

.buttonsWrapper {
  margin-top: 45px;
}

.cancelButton {
  margin-top: 15px;
}

.confirmationMessageWrapper {
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmationSuccessMark {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $accepted-color;
  border-radius: 50%;
  margin-right: 14px;
}

.confirmationSuccessText {
  color: $accepted-color;
  font-weight: 500;
  font-size: 22px;
}
