@import 'src/styles/variables/colors';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.hidden {
  display: none;
}

.modalTitle {
  font-size: 24px;
  line-height: 32px;
  color: #000;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.statusWrapper {
  display: flex;
  justify-content: flex-end;
}

.statusBadge {
  width: 120px;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
}

.statusText {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
}

.statusBadgeAccept {
  background-color: $accepted-color;
}

.statusBadgeWaiting {
  background-color: $waiting-color;
}

.statusBadgeRejected {
  background-color: $rejected-color;
}

.wrapper {
  position: relative;
}

.selectWrapper {
  position: absolute;
  left: 0;
  right: 0;
}

.accordionTitle {
  color: $primary-color;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    color: lighten($primary-color, 10%);

    svg path {
      stroke: lighten($primary-color, 10%);
    }
  }
}

.accordionIcon {
  margin-left: 10px;
  transform: rotate(-90deg);
  transition: 0.3s all;

  path {
    stroke: $primary-color;
  }
}

.accordionIconActive {
  transform: rotate(90deg);
}

.title {
  font-weight: 600;
  color: $primary-color;
}

.regular {
  margin-top: 8px;
}

.textWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.textFieldWrapper {
  margin-bottom: 20px;
}

.disabled {
  cursor: not-allowed;
}

.sublabel {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  color: $primary-color;
}

.error {
  color: $danger-color;
  transition: all 0.3s;
}

.button {
  margin-top: 10px;
}

.clickableText {
  display: flex;
  justify-content: center;
  gap: 7px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: $primary-color;
  cursor: pointer;

  &:hover {
    color: lighten($primary-color, 10%);

    > svg path {
      stroke: lighten($primary-color, 10%);
    }
  }
}

.phoneNumberInput {
  font-size: 20px;
}

.textButton {
  color: $primary-color;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;

  &:hover {
    color: lighten($primary-color, 10%);
  }
}

.removeButton {
  cursor: pointer;
}
