.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  box-sizing: border-box;
  margin: 0 auto;
  width: 300px;
  max-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: rgb(0 0 0 / 50%);
}
