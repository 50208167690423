@import 'src/styles/variables/media';

.root {
  position: relative;
  background: #fff;
  box-shadow: 0 2px 10px rgba(163 163 163 / 25%);
  border-radius: 8px;
  padding: 30px 35px 35px;
  width: 580px;
  min-height: 400px;

  @media ($phone) {
    min-height: 0;
    border-radius: unset;
    width: 100%;
    height: 100%;
  }
}

.loader {
  border-radius: 8px;

  @media ($phone) {
    border-radius: unset;
  }
}
