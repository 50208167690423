@import 'src/styles/variables/colors';

.root {
  overflow: auto;
  padding: 25px 7%;
  display: flex;
  column-gap: 20px;
  box-sizing: border-box;
  width: 100%;
  min-height: 500px;
}

.reminders {
  min-width: 230px;
  margin: 0 auto;
}

.dayTitle {
  text-align: center;
}

.reminder {
  margin-top: 8px;
  cursor: pointer;
  transition: 0.3s all;
}

.textEmpty {
  width: 230px;
  text-align: center;
  margin-top: 24px;
  color: $primary-color;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}

.errorTitle {
  font-weight: bold;
}

.errorDescription {
  margin-top: 6px;
}
