@import 'src/styles/variables/colors';

.root {
  display: flex;
  height: 100%;
  position: relative;
  overflow-x: hidden;
}

.content {
  width: 100%;
  height: 100%;
  background: $light-blue;
  overflow-x: hidden;
}
