@import 'src/styles/variables/colors';

.modal {
  text-align: center;
}

.modalTitle {
  font-size: 24px;
  line-height: 32px;
  color: #000;
}

.image {
  max-width: 50%;
}
