.root {
  max-width: 856px;
  margin: 50px auto;
  padding: 0 50px;

  * {
    user-select: auto;
  }
}

.title {
  font-size: 40px;
  margin-bottom: 10px;
}

.h3 {
  font-size: 30px;
  margin-bottom: 5px;
}

.h4 {
  font-size: 25px;
  margin-bottom: 5px;
}

.h5 {
  font-size: 20px;
  margin-bottom: 5px;
}

.list {
  padding-left: 30px;
  list-style: none;
}

.dottedList {
  padding-left: 30px;
}
