@import 'src/styles/variables/colors';

.calendar {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  p {
    font-size: 16px;
    line-height: 21px;
    color: $text-primary;
    transition: 0.3s all;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.tooltipOverlay {
  max-width: 250px;
  word-wrap: break-word;
}

.tooltipText {
  color: #fff;
  margin-top: 2px;
  font-size: 14px;
  line-height: 19px;
  user-select: auto;
}

.disabled {
  cursor: not-allowed;

  p {
    opacity: 0.4;
  }
}
