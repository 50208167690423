@import 'src/styles/variables/colors';

.textWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.textFieldWrapper {
  margin-bottom: 12px;
}

.textField {
  font-size: 18px;
  line-height: 26px;
  padding: 12px 16px;
}

.label {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

.sublabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: $primary-color;
}

.error {
  color: $danger-color;
  border-color: $danger-color !important;
  outline-color: $danger-color !important;
  transition: all 0.3s;
}

.larger {
  font-size: x-large;
  transition: all 0.1s;
}

.button {
  margin-top: 30px;
  max-width: 111px;
  font-size: 18px;
  line-height: 24px;
  padding: 10px;
}

.tooltipLink {
  cursor: pointer;
  &:hover {
    path {
      fill: lighten(#6c6c6c, 10%);
    }
  }
}

.textButton {
  color: $primary-color;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;

  &:hover {
    color: lighten($primary-color, 10%);
  }
}

.removeButton {
  cursor: pointer;
}
