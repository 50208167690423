@import 'src/styles/variables/colors';

.subtitle {
  margin-top: 20px;
}

.text {
  margin-top: 3px;
  display: inline-block;
  font-size: 15px;
  line-height: 20px;
}

.title {
  margin-top: 35px;
  font-size: 25px;
  line-height: 33px;
}

.switchWrapper {
  margin-top: 17px;
  display: flex;
  column-gap: 15px;
}

.switchLabel {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #8f8f8f;
  transition: 0.3s all;

  &.selected {
    color: $primary-color;
  }
}

.plans {
  display: flex;
  flex-flow: row wrap;
  column-gap: 32px;
  margin-top: 30px;
}

.features {
  margin-bottom: 48px;
}

.cancelButton {
  cursor: pointer;
  text-decoration: underline;
  transition: 0.3s all;
  color: $danger-color;
  margin-top: 48px;

  &:hover {
    color: lighten($danger-color, 30%);
  }
}
