@import 'src/styles/variables/colors';

.stack {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  cursor: initial;
}

.title {
  display: block;
  font-size: 0.9rem;
  margin: 0.5rem 0 0 0;
}

.suggestedTitle {
  font-size: 0.8rem;
  font-weight: 500;
  color: #c546c7;
}

.btnAddRecipient {
  display: block;
  font-size: 0.85rem;
  font-weight: bold;
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  padding: 0;
  text-align: left;
}

.btnRemoveRecipient {
  background-color: rgba(162, 162, 162, 0.1);
  border-radius: 50%;
  border: 1px solid rgba(162, 162, 162, 0.1);
  padding: 1px;
  cursor: pointer;
}

.btnRemoveRecipient:hover {
  border-color: rgba(162, 162, 162, 0.5);
}

.phoneInputStack {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.phoneInputContainer {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.phoneInputContainer .phoneInput {
  font-size: 0.8rem !important;
  padding: 0 0 0 2.8rem !important;
}

.phoneInputContainer .phoneInput.suggestedPhoneInput {
  background-color: rgba(197, 70, 199, 0.15) !important;
}

.btnSave {
  padding: 0.3rem;
  font-size: 1.1rem;
}

.error {
  color: $danger-color;
  font-size: 0.9rem;
  transition: all 0.3s;
}
