@import 'src/styles/variables/colors';

.wrapper {
  background-color: $light-blue;
}

.root {
  max-width: 588px;
}

.profileInfo {
  margin-top: 20px;
  max-width: 640px;
}

.infoBlock {
  margin-top: 15px;

  .infoText {
    font-weight: 500;
    word-break: break-all;
  }
}

.tooltipLink {
  cursor: pointer;
  &:hover {
    path {
      fill: lighten(#6c6c6c, 10%);
    }
  }
}
