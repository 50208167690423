@import 'src/styles/variables/colors';

.root {
  display: flex;
  justify-content: space-between;
}

.navigationItem {
  cursor: pointer;
  flex: 1;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid $sub-primary-color;
  text-decoration: none;
  transition: all 0.3s;

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }

  &:hover {
    background: lighten($sub-primary-color, 15%);
  }
}

.active {
  background: $sub-primary-color;

  &:hover {
    background: $sub-primary-color;
  }
}

.navigationText {
  cursor: pointer;
  margin-top: 5px;
  font-weight: 500;
  color: #000;
}
