@import 'src/styles/variables/colors';

.messageWrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.successMark {
  height: 62px;
  width: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $accepted-color;
  border-radius: 50%;
  margin-bottom: 30px;
}

.checkIcon {
  width: 30px;
  height: 33px;
}

.text {
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  color: #000;
}

.close {
  position: absolute;
  right: 25px;
  top: 23px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover {
    path {
      stroke: lighten(#3a3a3a, 20%);
    }
  }
}
