.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0 0 0 / 20%);
  overflow-y: auto;
  z-index: 2;
}

.modal {
  display: block;
  margin: 250px auto 100px;
  max-width: 608px;
  background: #fff;
  box-shadow: 0 2px 10px rgba(163 163 163 / 25%);
  border-radius: 8px;
  position: relative;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  padding: 30px;
}

.content {
  display: flex;
  gap: 15px;
}

.errorWrapper {
  display: inherit;
}

.text {
  display: flex;
  flex-direction: column;
}

.close {
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover {
    path {
      stroke: lighten(#3a3a3a, 20%);
    }
  }
}
