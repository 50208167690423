@import 'src/styles/variables/colors';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.wrapper {
  max-width: 991px;
  text-align: center;
}

.title {
  color: $primary-color;
  margin-top: 90px;
}

.description {
  margin-top: 30px;
  margin-bottom: 19px;
}

.button {
  margin-top: 100px;
}
