@import 'src/styles/variables/colors';

.modal {
  text-align: center;
}

.modalTitle {
  font-size: 24px;
  line-height: 32px;
  color: #000;
}

.image {
  max-width: 50%;
}

.continueWithGoogle {
  border: 1px solid #bbb;
  border-radius: 5px;
  font-size: 18px;
  padding: 13px;
  width: 250px;
  cursor: pointer;
}

.continueWithGoogle svg {
  width: 18px;
  margin-right: 10px;
}
