.root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  margin: 20px auto 30px;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
}
