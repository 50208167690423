@import 'src/styles/variables/colors';

.loaderWrapper {
  color: $sub-primary-color;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  opacity: 1;
  transform: translate(-50%, -50%);
  transition: opacity 0s linear, left 0s 0s;
}

.loaderWrapperFull {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loaderWrapperAccent {
  background-color: rgba(230 230 230 / 20%);
}

.hidden {
  transition: opacity 0.3s ease-in, left 0s 0.3s;
  left: -3000px;
  opacity: 0;
}

.text {
  text-align: center;
}

.loader,
.loader::before,
.loader::after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: $sub-primary-color;
  font-size: 8px;
  position: relative;
  text-indent: -9999em;
  animation-delay: -0.16s;
}

.loader::before,
.loader::after {
  content: '';
  position: absolute;
  top: 0;
}

.loader::before {
  left: -3.5em;
  animation-delay: -0.32s;
}

.loader::after {
  left: 3.5em;
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
