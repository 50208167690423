@import 'src/styles/variables/colors';

.overlay {
  max-width: 350px;
}

.root {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
  color: $primary-color;

  &:active {
    color: $primary-color;
  }

  svg {
    cursor: pointer;
  }
}

.bodyText {
  color: #fff;
  font-size: 14px;
  line-height: 19px;
  user-select: auto;

  a {
    color: #fff;
  }
}

.text {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  color: $primary-color;
  margin-right: 6px;
}
