@import 'src/styles/variables/colors';

.input {
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 20px !important;
  line-height: 26px !important;
  letter-spacing: 0.03em !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  padding: 14px 16px 14px 48px !important;
  width: 100% !important;
  border: 1px solid $primary-color !important;
  transition: all 0.3s !important;
  outline: 1px solid transparent !important;
  height: auto !important;

  &::placeholder {
    color: $text-placeholder !important;
  }

  &:active,
  &:focus {
    outline: 1px solid $primary-color !important;
  }

  &:disabled {
    border: 1px solid $disabled-secondary-color !important;
    outline: none !important;
    background-color: $disabled-secondary-background-color !important;
  }
}

.inputError {
  border-color: $danger-color !important;

  &:hover {
    border-color: $danger-color !important;
  }

  &:active,
  &:focus {
    outline: 1px solid $danger-color !important;
  }
}

.button {
  font-size: 20px !important;
  line-height: 26px !important;
  border: 1px solid transparent !important;
  border-right: 1px solid $primary-color !important;
  background-color: transparent !important;
  transition: all 0.3s !important;
  outline: 1px solid transparent !important;
  border-radius: 5px 0 0 5px !important;

  &:hover {
    background-color: transparent !important;
    border-radius: 5px 0 0 5px !important;
  }

  &:active,
  &:focus {
    border-radius: 5px 0 0 5px !important;
    background-color: transparent !important;
  }

  &:disabled {
    border-right: 1px solid $disabled-secondary-color !important;
    background-color: $disabled-secondary-background-color !important;
  }

  :global {
    .selected-flag.open {
      background: transparent !important;
      border-radius: none !important;
    }

    .selected-flag:hover {
      background: transparent !important;
    }
  }
}

.buttonDisabled {
  cursor: not-allowed !important;
  border-right: 1px solid $disabled-secondary-color !important;
  background-color: $disabled-secondary-background-color !important;

  &:active,
  &:focus,
  &:hover {
    background-color: $disabled-secondary-background-color !important;
  }

  :global {
    .selected-flag.open {
      background-color: $disabled-secondary-background-color !important;
    }

    .selected-flag:hover {
      background-color: $disabled-secondary-background-color !important;
    }

    .selected-flag:focus {
      background-color: $disabled-secondary-background-color !important;
    }
  }
}

.buttonError {
  border-right: 1px solid #cf1717 !important;

  &:active,
  &:focus {
    outline: 1px solid transparent !important;
  }
}
