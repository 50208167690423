.root {
  width: 680px;
  margin: 50px auto 0;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.stepper {
  padding-top: 20px;
}

.text {
  text-align: center;
}

.absoluteWrapper {
  position: absolute;
  top: 40px;
  right: 0;
}
