@import './variables/colors';
@import './tooltip';

html,
body,
#root {
  height: 100%;
  margin: 0;
  font-family: 'Red Hat Display', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
