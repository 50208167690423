@import 'src/styles/variables/colors';

.root {
  margin-top: 16px;
  display: flex;

  svg[name='error'] {
    width: 16px;
    height: 14px;
  }

  span {
    font-size: 13px;
    line-height: 17px;
    color: $danger-color;
    margin-left: 6px;
  }
}
