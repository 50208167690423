@import 'src/styles/variables/colors';

.root {
  padding: 20px 20px 150px 20px;
}

.title {
  font-size: 25px;
  line-height: 33px;
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.name {
  font-weight: 500;
}
