@import 'src/styles/variables/colors';

.root {
  display: flex;
  flex-direction: column;
}

.labelWrapper {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.single {
  display: block;
}

.label {
  color: $text-secondary;
  align-self: end;
  transition: all 0.3s;
}

.labelSecondary {
  font-weight: bold;
  color: #636363;
}

.labelError {
  color: $danger-color;
}

.input {
  font-family: 'Red Hat Display', sans-serif;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.03em;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 14px 16px;
  width: 100%;
  border: 1px solid $primary-color;
  transition: all 0.3s;
  outline: 1px solid transparent;

  &::placeholder {
    color: $text-placeholder;
  }

  &:active,
  &:focus {
    outline: 1px solid $primary-color;
  }

  &:disabled {
    border: 1px solid $disabled-secondary-color;
    outline: none;
    cursor: not-allowed;
  }
}

.inputSecondary {
  color: #636363;
}

.inputError {
  border-color: $danger-color !important;

  &:active,
  &:focus {
    outline-color: $danger-color !important;
  }
}

.error {
  margin-top: 8px;
  color: $danger-color;
  font-size: 14px;
  line-height: 19px;
  transition: all 0.3s;
}

.textarea {
  resize: none;
}
