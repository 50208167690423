.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0 0 0 / 20%);
  overflow-y: auto;
  z-index: 2;

  &.centered {
    display: flex;
  }
}

.modal {
  display: block;
  margin: 100px auto;
  max-width: 580px;
  background: #fff;
  box-shadow: 0 2px 10px rgba(163 163 163 / 25%);
  border-radius: 8px;
  position: relative;

  &.centered {
    margin: auto;
  }
}

.header {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  padding: 32px 32px 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.headerCenter {
  display: flex;
  justify-content: center;
}

.close {
  position: absolute;
  right: 25px;
  top: 23px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover {
    path {
      stroke: lighten(#3a3a3a, 20%);
    }
  }
}

.back {
  position: absolute;
  left: 25px;
  top: 23px;
  width: 26px;
  height: 26px;
  cursor: pointer;

  &:hover {
    path {
      stroke: lighten(#3a3a3a, 20%);
    }
  }
}

.content {
  padding: 32px;
}
