@import 'src/styles/variables/colors';

.inputWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.labelWrapper {
  display: block;
  margin-bottom: 6px;
  transition: all 0.3s;
}

.label {
  color: $text-secondary;
}

.labelError {
  color: $danger-color;
}

.error {
  margin-top: 8px;
  color: $danger-color;
  font-size: 14px;
  line-height: 19px;
  transition: all 0.3s;
}
