@import 'src/styles/variables/colors';
@import 'src/styles/variables/media';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
  background: linear-gradient(180deg, $primary-color 0%, #908ef2 100%);
  padding-top: 95px;
  padding-bottom: 95px;

  @media ($phone) {
    width: 100%;
    min-height: 100%;
    background: none;
    padding: 0;
  }
}
