@import 'src/styles/variables/colors';

.header {
  background: #f7f7f7;
  border-radius: 4px;
  display: flex;
  padding: 10px 15px;
}

.column {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: $text-placeholder;
  width: 190px;
  text-transform: uppercase;
}

.row {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px;
  border-bottom: 1px solid $border-gray-color;
}

.lastRow {
  border-bottom: none;
}

.rowItem {
  display: inline-block;
  width: 190px;
}
