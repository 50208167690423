@import 'src/styles/variables/colors';

.root {
  display: flex;
  height: 100%;
}

.content {
  margin-left: 247px;
  width: 100%;
  height: 100%;
  background: $light-blue;
  overflow-x: hidden;
  transition: margin-left 0.3s ease;

  &.contentExpanded {
    margin-left: 60px;
  }
}
