@import 'src/styles/variables/colors';

.row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 25px 0;
  border-bottom: 1px solid $border-gray-color;
}

.left {
  display: flex;
  align-items: center;
}

.right {
  display: flex;
  align-items: center;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  width: 290px;
}

.title {
  font-weight: 500;
}

.description {
  font-weight: 400;
  line-height: 16px;
  margin-top: 5px;
}

.message {
  margin-left: 55px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  width: 300px;

  &.error {
    color: $danger-color;
  }
}

.clickableText {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: $primary-color;
  cursor: pointer;
  text-decoration: underline;
  transition: 0.3s all;

  &:hover {
    color: lighten($primary-color, 15%);
  }
}

.tableTitleWrapper {
  margin-top: 25px;
  margin-bottom: 15px;
}

.statusWrapper {
  padding: 6.5px 19.5px;
  border-radius: 4px;
  max-width: 66px;
}

.status {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
}

.errorWrapper {
  background: rgba(174 29 29 / 17%);
}

.errorText {
  color: #ae1d1d;
}

.successWrapper {
  background: rgba(29 174 35 / 17%);
}

.successText {
  color: #1dae23;
}
