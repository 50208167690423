@import 'src/styles/variables/colors';

.root {
  width: 100%;
  height: 100%;
  padding: 0 0 45px;
}

.loaderWrapper {
  position: relative;
  height: 100%;
}

.wrapper {
  padding: 0 0 100px !important;
}

.primaryWrapper {
  background: rgba(87, 119, 218, 0.9);
  padding: 15px 20px 10px 20px;
}

.top {
  background: $primary-color;
}

.heading {
  padding: 0 48px;
  margin-bottom: 32px;
}

.title {
  margin-bottom: 5px;
  color: #fff;
  font-size: 30px !important;
  line-height: 40px !important;
}

.description {
  font-size: 17px;
  line-height: 22px;
  color: #ececec;
}

.profileInfo {
  p {
    color: #ececec;
  }

  svg {
    path {
      stroke: #ececec;
    }
  }
}

.flex {
  display: flex;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
}

.content {
  overflow: auto;
  width: 100%;
}

.icon {
  display: flex;
  align-items: center;
}

.tabsWrapper {
  padding: 0 48px 30px;
  display: grid;
  grid-template-columns: 4fr 6fr;
  overflow: hidden;
  column-gap: 20px;
}

.tabs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 16px;
  list-style: none;
}

.tabTitle {
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
}

.tabDescription {
  font-size: 14px;
  line-height: 19px;
}

.mainWidget {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  column-gap: 20px;
  min-width: 416px;
  transition: all 0.3s;
  background: #fff;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px 24px;

  .widgetTitle {
    font-weight: 700;
    color: $primary-color;
    margin-top: 2px;
  }

  .info {
    display: flex;
    column-gap: 13px;
    margin-top: 8px;
  }

  .item {
    display: flex;
    column-gap: 13px;
  }

  .divider {
    width: 1px;
    background: #dfdfdf;
  }

  .loaderWrapper {
    display: flex;
    justify-content: center;
  }

  .spinnerWrapper {
    width: 16px;
    height: 16px;
    display: inline-block;
    overflow: hidden;
    background: #fff;
  }

  .spinner {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.16);
    backface-visibility: hidden;
    transform-origin: 0 0;
  }

  .spinner div {
    position: absolute;
    width: 60px;
    height: 60px;
    border: 10px solid $primary-color;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    top: 50px;
    left: 50px;
    box-sizing: content-box;
  }

  .grayText {
    color: #828282 !important;
    margin-top: 0;
  }

  .boldText {
    margin-top: 2px;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: $text-primary;
  }

  .text {
    font-size: 12px;
    line-height: 16px;
    color: $text-primary;
    margin-top: 16px;
  }
}

.button {
  display: flex;
  align-items: center;
  column-gap: 6px;
  color: #55afb0;
  cursor: pointer;
  margin-top: 16px;

  &:hover {
    color: lighten(#55afb0, 15);

    svg {
      path {
        fill: lighten(#55afb0, 15);
      }
    }
  }
}

.tooltipOverlay {
  max-width: 200px;
  word-wrap: break-word;
}

.tooltipText {
  color: #fff;
}

.tab {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  min-width: 250px;
  min-height: 85.5px;
  transition: all 0.3s;
  background: #fff;
  border: 2.5px solid transparent;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 25px;
  overflow: hidden;

  &:hover {
    border-color: lighten($light-purple-color, 10%);
  }
}

.selected {
  border: 2.5px solid $light-purple-color;

  &:hover {
    border-color: $light-purple-color;
  }
}

.leftEnter {
  opacity: 0;
  transform: translateX(-1000px);
}

.leftEnterActive {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

.leftExit {
  opacity: 1;
  position: absolute;
  transform: translateX(0);
}

.leftExitActive {
  opacity: 0;
  transform: translateX(-1000px);
  transition: opacity 500ms, transform 500ms;
}

.rightEnter {
  opacity: 0;
  transform: translateX(1000px);
}

.rightEnterActive {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

.rightExit {
  opacity: 1;
  position: absolute;
  transform: translateX(0);
}

.rightExitActive {
  opacity: 0;
  transform: translateX(1000px);
  transition: opacity 500ms, transform 500ms;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
