.modal {
  width: 580px;
  min-height: 278px;
}

.content {
  padding: 14px 32px 32px;
}

.title {
  font-size: 24px;
  line-height: 32px;
}

.buttons {
  position: absolute;
  width: calc(100% - 65px);
  bottom: 32px;
  display: flex;
  justify-content: center;
  column-gap: 16px;

  button {
    max-width: 300px;
  }
}
