.body {
  display: none;
  transition: all 0.3s;
}

.title {
  user-select: none;
}

.active {
  display: block;
}
