.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 0.75rem;
  padding: 20px;
  border-radius: 0.5rem;
  width: 100%;
}

.titleSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
}

.title {
  font-weight: 800;
  font-size: 1.1rem;
  line-height: 1.2;
  color: #fff;
  @media (min-width: 1400px) {
    font-size: 1.25rem;
  }
}

.upgradeButton {
  font-family: 'Red Hat Display', sans-serif;
  text-transform: uppercase;
  font-size: 0.9rem;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  border: none;
  padding: 0.4rem 1rem;
  border-radius: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
  text-wrap: nowrap;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  min-width: max-content;

  & svg {
    display: none;
    transform: rotate(180deg);
    margin-right: 0.3rem;
    transition: all 0.3s;
    path {
      fill: rgba(87, 119, 218, 1);
    }
  }

  &:hover {
    animation: pulse 2s infinite;
    color: rgba(87, 119, 218, 1);

    & svg {
      display: inline-block;
    }
  }
  @keyframes pulse {
    0% {
      background-color: rgba(255, 255, 255, 1);
    }
    50% {
      background-color: rgba(255, 255, 255, 0.7);
    }
    100% {
      background-color: rgba(255, 255, 255, 1);
    }
  }

  @media (min-width: 1400px) {
    font-size: 0.875rem;
  }
}

.metricsContainer {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  @media (min-width: 1080px) {
    flex-wrap: nowrap;
  }
}

.error {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #b91e1e;
  color: #fff;
  padding: 0.5rem 20px;
  & p {
    color: #fff;
    font-size: 1rem;
  }
}

.errorIcon {
  font-size: 1.25rem;
}
