@import 'src/styles/variables/colors';

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 1230px !important;
}

.modalContent {
  width: 100vw;
  max-width: 1900px;
}

.modalTitle {
  font-size: 24px;
  line-height: 32px;
  color: #000;
}

.image {
  max-width: 50%;
}
