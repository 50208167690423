.wrapper {
  margin: 20px 0 25px;
  display: flex;
  justify-content: space-between;
  max-width: 265px;
}

.switchLabel {
  width: 50px;
  height: 25px;

  > div {
    width: 17px;
    height: 17px;
  }

  &:active > div {
    width: 20px;
  }
}

.text {
  font-weight: 500;
  font-size: 20px;
  color: #272727;
}

.button {
  margin-top: 30px;
  max-width: 111px;
  font-size: 18px;
  line-height: 24px;
  padding: 10px;
}

.errorTitle {
  font-weight: bold;
}

.errorDescription {
  margin-top: 6px;
}
