@import 'src/styles/variables/colors';

.root {
  border: 1px solid $border-color;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 12px;
  width: 230px;
  transition: all 0.3s;
  position: relative;
  background-color: white;
}

.statusAndSwitchContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.4rem;
}

.statusCircle {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statusCircleAccept {
  background-color: $accepted-color;
}

.statusCircleWaiting {
  background-color: $waiting-color;
}

.statusCircleRejected {
  background-color: $rejected-color;
}

.clickable {
  &:hover {
    border: 1px solid $primary-color;
    box-shadow: 2px 4px 4px rgb(0 0 0 / 8%);
  }
}

.flexWrapper {
  display: flex;
  justify-content: space-between;
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 200px;
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: transparent;
  flex-shrink: 0;
}

.title {
  font-weight: 600;
  font-size: 15px;
  font-style: normal;
  letter-spacing: 0.03em;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $primary-color;
}

.toggleWrapper {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.regular {
  font-size: 14px;
  line-height: 19px;
  margin-top: 6px;
  color: #636363;
}

.recurring {
  color: #636363;
}

.phoneWrapper {
  margin-top: 6px;
}

.phone {
  width: 100%;
  border: 2px solid #898989;
  border-radius: 5px;
  padding: 5px 8px;
}

.inputWrapper {
  width: 50%;
}

.bodyText {
  text-align: center;
  color: #fff;
  font-size: 14px;
  line-height: 19px;
  user-select: auto;
}

.tooltipOverlay {
  max-width: 200px;
}

.spinnerFlexWrapper {
  display: flex;
  align-items: center;
}

.spinnerWrapper {
  width: 16px;
  height: 16px;
  display: inline-block;
  overflow: hidden;
  background: #fff;
}

.spinner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.16);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.spinner div {
  position: absolute;
  width: 60px;
  height: 60px;
  border: 10px solid $primary-color;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  top: 50px;
  left: 50px;
  box-sizing: content-box;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.error {
  color: $danger-color;
  font-size: 0.9rem;
  transition: all 0.3s;
}
