@import 'src/styles/variables/colors';

.loadingWrapper {
  position: relative;
  margin-top: 75px;
}

.item {
  list-style: none;

  &:not(:first-child) {
    margin-top: 16px;
  }

  &:last-child {
    padding-bottom: 10px;
  }
}

.textButton {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: $primary-color;
  cursor: pointer;
  transition: 0.3s all;
  margin-top: 5px;
  margin-left: 32px;

  &:hover {
    color: lighten($primary-color, 15);

    svg {
      path {
        fill: lighten($primary-color, 15);
      }
    }
  }

  &:last-child {
    padding-bottom: 10px;
  }
}
