.modalTitle {
  font-size: 24px;
  line-height: 32px;
  color: #000;
}

.root {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.border {
  width: 452px;
  border-top: 1px solid #c9c9c9;
}

.phoneBody {
  width: 358px;
  position: relative;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/recordatorio-de-cita.appspot.com/o/phone-body.png?alt=media&token=fed4698d-b194-4030-851f-2bab97483545');
  background-repeat: repeat-y;
  background-position: 2px 0;
}

.phoneBottom {
  width: 358px;
  height: 131px;
  position: relative;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/recordatorio-de-cita.appspot.com/o/phone-bottom.png?alt=media&token=b3a4ea4f-c42b-4879-a893-c05345c28405');
  background-repeat: repeat-y;
  background-position: 0 0;
}

.message {
  word-break: break-all;
  white-space: pre-wrap;
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 20px;
  margin-bottom: 5px;
  margin-left: 38px;
  display: inline-block;
  background: #e5e5ea;
  max-width: 251px;
  font-size: 13px;
  line-height: 17px;

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    left: 31px;
    bottom: 5px;
    height: 20px;
    width: 20px;
    background: #e5e5ea;
    border-bottom-right-radius: 15px;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 28px;
    bottom: 5px;
    width: 10px;
    height: 20px;
    background: #fff;
    border-bottom-right-radius: 10px;
  }
}
