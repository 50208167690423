@import 'src/styles/variables/colors';

.h1,
.h2,
.h3,
.h4,
.h5,
.body,
.span {
  font-family: 'Red Hat Display', sans-serif;
  color: $text-primary;
  letter-spacing: 0.03em;
  margin: 0;
}

.h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.03em;
}

.h2 {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  line-height: 26px;
  letter-spacing: 0.03em;
}

.h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 33px;
}

.h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 33px;
}

.body {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.03em;
}

.span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.03em;
}
