@import 'src/styles/variables/colors';

.root {
  font-family: 'Red Hat Display', sans-serif;
  user-select: none;
  border-radius: 5px;
  font-style: normal;
  width: 100%;
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
  padding: 14px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s all;

  &:disabled {
    cursor: not-allowed;
    background: $disabled-button-color;
  }
}

.primary {
  background: $primary-color;
  color: #fff;

  &:hover:not(:disabled) {
    background: darken($primary-color, 3%);
  }
}

.secondary {
  background: #e5e7e6;
  color: rgb(0 0 0 / 52%);

  &:hover:not(:disabled) {
    background: darken(#e5e7e6, 4%);
  }
}

.danger {
  background: $rejected-color;
  color: #fff;

  &:hover:not(:disabled) {
    background: darken($rejected-color, 4%);
  }
}

.withBorder {
  background: #fff;
  border: 1px solid $primary-color;
  color: $primary-color;

  &:hover:not(:disabled) {
    background: $primary-color;
    color: #fff;
  }
}

.content {
  display: grid;
  align-self: center;
}

.contentCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderWrapper {
  width: 34px;
  height: 19px;
}

.loader,
.loader::before,
.loader::after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  animation: load7 1.8s infinite ease-in-out both;
}

.loader {
  color: #fff;
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  transform: translateY(-5px) translateX(13px);
  animation-delay: -0.16s;
}

.loader::before,
.loader::after {
  content: '';
  position: absolute;
  top: 0;
}

.loader::before {
  left: -3.5em;
  animation-delay: -0.32s;
}

.loader::after {
  left: 3.5em;
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
