@import 'src/styles/variables/colors';

.price {
  display: flex;
  margin-top: 7px;
  align-items: flex-end;

  h1,
  span {
    color: $primary-color;
  }

  h1 {
    letter-spacing: 0.03em;
  }

  span {
    margin-bottom: 5px;
    margin-left: 5px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
  }
}

.button {
  margin-top: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;
  padding: 7.5px 14.5px;
  border-radius: 4px;
  max-width: 120px;

  &:disabled {
    color: #a9a9a9;
    background-color: #f4f4f4;
  }
}

.features {
  margin-top: 15px;
}

.cancelButton {
  cursor: pointer;
  text-decoration: underline;
  transition: 0.3s all;
  color: $danger-color;
  margin-top: 48px;

  &:hover {
    color: lighten($danger-color, 30%);
  }
}
