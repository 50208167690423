.status {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0.5rem;
  border-radius: 0.2rem;
  font-size: 0.8rem;
  color: white;
  text-transform: capitalize;
}

.statusError {
  background-color: #d74e3c;
}

.statusInactive {
  color: #000;
  background-color: #e5e7eb;
}

.statusReview {
  background-color: #c546c7;
}

.statusScheduled {
  background-color: #10b981;
}

.statusSent {
  background-color: #4465c1;
}
