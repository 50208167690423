@import 'src/styles/variables/colors';

.root {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  padding: 20px 0 0;
  width: 247px;
  height: 100%;
  background: #fff;
  overflow-y: auto;
  transition: all 0.2s ease;

  &.collapsed {
    width: 60px;
    overflow: visible;

    .title {
      opacity: 0;
      display: none;
    }

    .header {
      margin-bottom: 26px;
    }

    .collapseIcon {
      path {
        fill: $primary-color;
      }
    }
  }

  &.collapsed:hover {
    width: 247px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    background: #fff;
    overflow-y: auto;
  }
}

.header {
  display: flex;

  align-items: center;
  justify-content: space-between;
  padding: 0 19px;
  margin-bottom: 24px;
  position: relative;
}

.title {
  display: block;
  font-size: 1.5rem;
  font-weight: 800;
  text-wrap: nowrap;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.collapseButton {
  width: 24px;
  height: 24px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;

  &.rotated {
    transform: rotate(180deg);
  }
}

.collapseIcon {
  width: 16px;
  height: 16px;

  path {
    fill: $border-light-gray-color;
    transition: 0.3s all;
  }

  &:hover {
    path {
      fill: $primary-color;
    }
  }
}

.sidebar {
  width: 100%;
}

.icon rect,
.icon path {
  fill: $border-light-gray-color;
  transition: 0.3s all;
}

.text {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #b5b5b5;
  transition: 0.3s all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebarItem {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 20px;
  background-color: transparent;
  padding: 15px 19px;
  width: 100%;
  cursor: pointer;
  transition: 0.3s all;

  @media (min-width: 1400px) {
    padding: 17px 19px;
  }

  &:hover {
    background-color: $light-blue;

    .text {
      color: $primary-color;
    }

    .icon rect,
    .icon path {
      fill: $primary-color;
    }
  }
}

.active {
  background-color: $light-blue;

  .text {
    color: $primary-color;
  }

  .icon rect,
  .icon path {
    fill: $primary-color;
  }
}

.lineWrapper {
  margin-top: 0;
}

.line {
  width: 100%;
  border-top: 1px solid $border-color;
  border-bottom: none;

  @media (min-width: 1400px) {
    border-bottom: 1px solid $border-color;
  }
}

.wrapper {
  padding: 10px 20px 0;
}

.secondaryTitle {
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
}

.calendarList {
  margin-top: 15px;
  padding: 0 20px;
  max-height: 200px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.25em;
    height: 0.25em;
  }

  &::-webkit-scrollbar-thumb {
    background: #96989b;
    border-radius: 10px;
    opacity: 0.7;
    overflow: auto;
  }
}
