@import 'src/styles/variables/colors';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 10px 10px;
  flex: 1;
}

.wrapper {
  flex: 1;
}

.textWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.textFieldWrapper {
  margin-bottom: 25px;
}

.sublabel {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  color: $primary-color;
}

.clickableText {
  color: $primary-color;
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    color: lighten($primary-color, 5%);
  }
}

.timing {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 25px;
  margin-top: 14px;

  .timingList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 7px;
  }
}

.timingItem {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;

  p {
    font-size: 14px;
  }
}

.text {
  line-height: initial;
}

.button {
  margin: 30px 0;
}

.error {
  color: $danger-color;
  border-color: $danger-color !important;
  outline-color: $danger-color !important;
  transition: all 0.3s;
}

.larger {
  font-size: x-large;
  transition: all 0.1s;
}

.bodyText {
  margin-top: 45px;
}

.confirmationWrapper {
  margin-top: 35px;
}

.checkboxesWrapper {
  display: flex;
  gap: 34px;
  margin-top: 20px;
}

.checkboxWrapper {
  display: flex;
}

.confirmationLabel {
  margin-left: 15px;
}

/* review */

.messageContainer {
  position: relative;
}

.message {
  word-break: break-all;
  white-space: pre-wrap;
  position: relative;
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 20px;
  margin-bottom: 5px;
  display: inline-block;
  background: #e5e5ea;
  max-width: 251px;
  font-size: 13px;
  line-height: 17px;

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: -7px;
    height: 20px;
    width: 20px;
    background: #e5e5ea;
    border-bottom-right-radius: 15px;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -10px;
    width: 10px;
    height: 20px;
    background: #fff;
    border-bottom-right-radius: 10px;
  }
}

.textButton {
  color: $primary-color;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;

  &:hover {
    color: lighten($primary-color, 10%);
  }
}

.removeButton {
  cursor: pointer;
}
