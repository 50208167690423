.card {
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.75rem;
  border-radius: 0.25rem;
  width: 190px;
  @media (min-width: 1400px) {
    width: 260px;
  }
}

.icon {
  color: #fff;
  line-height: 1rem;
  font-size: 1.5rem;
  opacity: 0.8;
  display: none;
  @media (min-width: 1400px) {
    display: block;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.value {
  font-size: 1.75rem;
  font-weight: 600;
  color: #fff;
  @media (min-width: 1400px) {
    font-size: 2rem;
  }
}

.label {
  opacity: 0.8;
  color: #fff;
  font-size: 0.8rem;
  line-height: 1.4;
  max-width: 100px;
  @media (min-width: 1400px) {
    font-size: 1rem;
    max-width: 120px;
  }
}
