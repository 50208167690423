.modalTitle {
  font-size: 24px;
  line-height: 32px;
  color: #000;
}

.root {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.border {
  width: 452px;
  border-top: 1px solid #c9c9c9;
}
