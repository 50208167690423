@import 'src/styles/variables/colors';

.tooltipOverlay {
  max-width: 350px;
}

.tooltipLink {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: $primary-color;

  &:active {
    color: $primary-color;
  }
}

.tooltipText {
  text-decoration: underline;
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: $primary-color;
  margin-right: 6px;
}

.flexWrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;

  &:hover {
    .clickableText {
      color: lighten($primary-color, 5%);
    }

    .arrowDown {
      path {
        fill: lighten($primary-color, 5%);
      }
    }
  }
}

.clickableText {
  color: $primary-color;
  margin-top: 10px;
}

.bodyText {
  color: #fff;
  font-size: 14px;
  line-height: 19px;
  user-select: auto;
}

.textUnderline {
  text-decoration-line: underline;
}

.arrowDown {
  transition: 0.3s all;
  transform: rotate(-90deg);

  path {
    fill: $primary-color;
  }
}

.rotatedArrow {
  transform: rotate(90deg);
}
