@import 'src/styles/variables/colors';

.root {
  padding-top: 20px;
}

.remindersList {
  overflow: auto;
  padding: 25px 0 200px 0;
  display: flex;
  column-gap: 12px;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
}

.remindersListItem {
  margin: 0 auto;
}

.heading {
  display: flex;
  justify-content: space-between;
}

.dateHeading {
  display: flex;
  gap: 16px;
  align-items: center;
}

.leftButtons {
  display: flex;
}

.arrow {
  cursor: pointer;
}

.arrowWrapper {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.3s;
  border-radius: 50%;
  outline: 1px solid transparent;

  &:hover {
    background-color: lighten($primary-color, 36%);
  }

  &:active {
    background-color: lighten($primary-color, 30%);
  }
}

.button {
  background: #fff;
  border: 1px solid $border-color;
  border-radius: 3px;
  color: $text-primary;
  width: 92px;
  height: 40px;
  padding: 9px 22px;
  font-size: 16px;
  line-height: 21px;
  margin-left: 15px;

  &:hover {
    background: darken(#fff, 5%) !important;
  }
}

.month {
  font-weight: bold;
  font-size: 23px;
  line-height: 30px;
  color: $text-primary;
}

.dayTitle {
  text-align: center;
}

.reminder {
  margin-top: 12px;
  transition: 0.3s all;
}

.textEmpty {
  width: 230px;
  text-align: center;
  margin-top: 24px;
  color: $primary-color;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}

.arrowRight {
  transform: rotate(180deg);
}

.errorTitle {
  font-weight: bold;
}

.errorDescription {
  margin-top: 6px;
}

.loader {
  margin-top: 50px;
}
