.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.title {
  text-align: center;
  font-size: 25px;
  line-height: 33px;
  margin-bottom: 11px;
}

.description {
  text-align: center;
  margin-bottom: 30px;
}
