@import 'src/styles/variables/colors';

.root {
  display: flex;
}

.stepper {
  margin-top: 26px;
  margin-right: 16px;
  display: flex;
  justify-self: center;
  align-items: center;
  flex-direction: column;
}

.lineWrapper {
  flex: 1 1 auto;
}

.line1 {
  display: block;
  border-right: 2px solid $primary-color;
  justify-content: center;
  height: 95px;
}

.line2 {
  display: block;
  border-right: 2px solid $primary-color;
  justify-content: center;
  height: 230px;
}

.circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  position: relative;
  transition: all 0.3s;
  background: #fff;
  border: 8px solid $primary-color;
}

.step {
  display: flex;
  flex-direction: column;
  margin-top: 26px;
}

.text {
  margin-top: 10px;
}
